<template>
    <MainFunctions :buttons="buttons" :title="$t('menu.nav-staff-title')"></MainFunctions>
</template>

<script>
import store from '@/store'
import MainFunctions from "@/components/MainFunctions"

export default {
    name: 'StaffMenu',
    components: {
        MainFunctions
    },
    data() {
        if (store.getters.user.isService) {
            return {
                buttons: [
                    { title: '儲格檢查', color: "light-green darken-4", icon: 'grid_view', routeName: 'doorCheck'}
                ]
            }
        } else {
            return {
                buttons: [
                    { title: '投件', color: "success", icon: 'move_to_inbox', routeName: 'staffPutin'},
                    { title: '櫃體設定', color: "purple darken-2", icon: 'build', routeName: 'boxSetting'},
                    { title: '儲格檢查', color: "light-green darken-4", icon: 'grid_view', routeName: 'doorCheck'},
                    { title: '遙控電源', color: "blue-grey darken-2", icon: 'power_settings_new', routeName: 'boxReopen'}
                ]
            }
        }
    }
}
</script>
